@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

html {
  background-color: black;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

canvas {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-end;
  }
}

.link {
  font-size: 1rem;
  font-family: 'montserrat';
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.7;
  margin-top: 0.5rem;
  margin-right: 2rem;
  z-index: 1;
  color: white;
  transition: opacity 0.35s ease-in-out;
}

.link:hover {
  opacity: 1;
}
